import React, {useEffect, useMemo, useState} from 'react';
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import SearchForm, {
    SearchParameters,
    searchParametersToUrlParams
} from "./SearchForm";
import {useTranslation} from "react-i18next";

const SearchIcon = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const {lang} = useParams();
  const [showModal, setShowModal] = useState(false);

  const propertiesParams = useMemo(() => {
      return {
        propertyStatus: searchParams.get('propertyStatus') || null,
        propertyType: searchParams.get('propertyType') || null,
        city: searchParams.get('city') || null,
        priceFrom: searchParams.get('priceFrom') || null,
        priceTo: searchParams.get('priceTo') || null,
        rooms: searchParams.get('rooms') || null,
        propertyFeatures: searchParams.get('propertyFeatures') ? searchParams.get('propertyFeatures')?.split(',').filter(feature => feature !== '') : null,
        zones: searchParams.get('zones') ? searchParams.get('zones')?.split(',') : null,
      };
  }, [searchParams]);

  useEffect(() => {
      (showModal) ? document.body.style.overflow = 'hidden' : document.body.style.overflow = 'visible'
  });

  const onSearch = (searchParameters: SearchParameters) => {
      const propertiesQueryParams = searchParametersToUrlParams(searchParameters);
      navigate(`/${lang}/properties?${propertiesQueryParams.toString()}`);
  }

  return (
      <>
          {showModal ? (
                  <>
                      <button onClick={() => setShowModal(!showModal)}
                              className="lg:hidden fixed w-14 h-14 top-5 right-5 border border-gray-300 bg-gray-100 text-gray-500 rounded-full text-center z-50 shadow-lg cursor-pointer">
                          <div className="flex justify-center items-center">
                              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24"
                                   stroke="currentColor" strokeWidth="2">
                                  <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"/>
                              </svg>
                          </div>
                      </button>
                      <SearchForm isHorizontal={false} initialState={propertiesParams} onSearch={onSearch}/>
                  </>
              ) :
              <button onClick={() => setShowModal(!showModal)}
                      className="lg:hidden fixed bottom-0 py-3 w-full border border-gray-300 bg-gray-800 text-white text-center shadow-lg cursor-pointer">
                  <div className="flex justify-center items-center gap-x-2 z-50">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24"
                           stroke="currentColor"
                           strokeWidth="2">
                          <path strokeLinecap="round" strokeLinejoin="round"
                                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"/>
                      </svg>
                      {t('buttons.search-properties')}
                  </div>
              </button>
          }
      </>
  );
}

export default SearchIcon;
