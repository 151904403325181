import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Link, useLocation} from "react-router-dom";
import {Data, getPropertiesStatus, PropertyStatusAttributes} from "../../services/dataService";

interface FooterProps {
  cookieConsent: boolean;
}

interface FormDataElement {
    key: string;
    value: string;
}

const convertPropertyStatus = (list: Data<PropertyStatusAttributes>[], currentLang: string, t:any): FormDataElement[] => {
    const result = list.map(propertyStatus => {
        let translatedValue = null;

        for (const translation of propertyStatus.attributes.translations) {
            if (translation.language === currentLang) {
                translatedValue = translation.value;
                break;
            }
        }

        if (!translatedValue) {
            translatedValue = propertyStatus.attributes.slug;
        }

        return {
            key: propertyStatus.attributes.slug,
            value: translatedValue,
        };
    });

    result.sort((a: FormDataElement, b: FormDataElement) => a.value.localeCompare(b.value));

    return [...result];
}

const Footer = ({ cookieConsent }: FooterProps) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.resolvedLanguage;
  const [propertyStatusList, setPropertyStatusList] = useState<Data<PropertyStatusAttributes>[]>([]);
  const [propertyStatusFormData, setPropertyStatusFormData] = useState<FormDataElement[]>([]);
  const location = useLocation();

  const pathSegments = location.pathname.split('/').filter(Boolean);
  const lastSegment = pathSegments[pathSegments.length - 1];

    useEffect(() => {
        getPropertiesStatus()
            .then(setPropertyStatusList);
    }, []);

    useEffect(() => {
        Promise.resolve(propertyStatusList)
            .then(list => convertPropertyStatus(list, i18n.resolvedLanguage, t))
            .then(setPropertyStatusFormData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n.resolvedLanguage, propertyStatusList]);

  const resetCookies = () => {
      localStorage.setItem('cookieConsent', JSON.stringify(false));
      localStorage.setItem('analyticsStorage', JSON.stringify(false));
      localStorage.setItem('adStorage', JSON.stringify(false));
      window.location.reload();
  }

  return (
      <footer className="bg-white py-5 md:pt-16">

          <div className="container max-w-6xl mx-auto px-4">

              <div className="flex flex-col lg:flex-row justify-between">
                  <div className="flex flex-col justify-start items-center lg:items-start text-center lg:text-left mb-10 lg:mb-0">
                      <div className="flex justify-center lg:justify-start mb-5 w-24">
                          <img src={`${process.env.PUBLIC_URL}/images/reesman.png`} alt="Image" />
                      </div>

                      <p className="font-light text-gray-400 text-lg mb-10">Reesman. {t('footer.copyright')}</p>

                      <div className="flex items-center justify-center lg:justify-start space-x-5">
                          <a href="https://m.facebook.com/reesmanasesores/" target="_blank"
                             className="px-3 py-3 bg-gray-200 text-gray-700 rounded-full hover:bg-gray-800 hover:text-white transition ease-in-out duration-500">
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                   fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                   strokeLinejoin="round" className="feather feather-facebook">
                                  <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path>
                              </svg>
                          </a>

                          <a href="https://www.instagram.com/reesman_inmobiliaria/" target="_blank"
                             className="px-3 py-3 bg-gray-200 text-gray-700 rounded-full hover:bg-gray-800 hover:text-white transition ease-in-out duration-500">
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                   fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                   strokeLinejoin="round" className="feather feather-instagram">
                                  <rect x="2" y="2" width="20" height="20" rx="5" ry="5"></rect>
                                  <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path>
                                  <line x1="17.5" y1="6.5" x2="17.51" y2="6.5"></line>
                              </svg>
                          </a>

                          <a href="https://www.youtube.com/@reesman_inmobiliaria" target="_blank"
                             className="px-3 py-3 bg-gray-200 text-gray-700 rounded-full hover:bg-gray-800 hover:text-white transition ease-in-out duration-500">
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                   fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                   strokeLinejoin="round" className="feather feather-youtube">
                                  <path
                                      d="M22.54 6.42a2.78 2.78 0 0 0-1.94-2C18.88 4 12 4 12 4s-6.88 0-8.6.46a2.78 2.78 0 0 0-1.94 2A29 29 0 0 0 1 11.75a29 29 0 0 0 .46 5.33A2.78 2.78 0 0 0 3.4 19c1.72.46 8.6.46 8.6.46s6.88 0 8.6-.46a2.78 2.78 0 0 0 1.94-2 29 29 0 0 0 .46-5.25 29 29 0 0 0-.46-5.33z"></path>
                                  <polygon points="9.75 15.02 15.5 11.75 9.75 8.48 9.75 15.02"></polygon>
                              </svg>
                          </a>
                      </div>
                  </div>

                  <div className="text-center lg:text-left mb-10 lg:mb-0">
                      <h4 className="font-semibold text-gray-900 text-2xl mb-6">{t('nav-items.contact')}</h4>

                      <Link to={`/${currentLanguage}/register-property`}
                            className="block font-light text-gray-400 text-xl mb-6 hover:text-gray-800 transition ease-in-out duration-300">{t('nav-items.register')}</Link>

                      <Link to={`/${currentLanguage}/contact`}
                            className="block font-light text-gray-400 text-xl mb-6 hover:text-gray-800 transition ease-in-out duration-300">{t('nav-items.contact')}</Link>
                  </div>

                  <div className="text-center lg:text-left mb-10 lg:mb-0">
                      <h4 className="font-semibold text-gray-900 text-2xl mb-6">{t('footer.real-state')}</h4>

                      {propertyStatusFormData.map(element => (
                          <Link key={element.key} to={`/${currentLanguage}/properties?propertyStatus=${element.key}`}
                                className="block font-light text-gray-400 text-xl mb-6 hover:text-gray-800 transition ease-in-out duration-300">{element.value}</Link>
                      ))}
                  </div>
              </div>

              <hr className="mt-6" />

              <div className={`flex flex-col lg:flex-row justify-center lg:justify-between items-center mt-6 ${lastSegment === 'properties' ? 'pb-10' : ''}`}>
                  <div className="flex justify-center items-center text-xs sm:text-sm text-gray-400">
                     <span>Made with</span>
                     <svg xmlns="http://www.w3.org/2000/svg" className="mx-1 h-5 w-5 text-red-600" viewBox="0 0 20 20"
                          fill="currentColor">
                         <path fillRule="evenodd"
                               d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z"
                               clipRule="evenodd"/>
                     </svg>
                     <span>by <a href="https://www.aldanux.es"
                                 target="_blank">Aldanux</a>.</span>
                  </div>
                  <div className="flex flex-col sm:flex-row items-center justify-center space-y-2 md:space-y-0 md:space-x-2 mt-2 sm:mt-0">
                      <Link to={`/${i18n.resolvedLanguage}/legal`} className="hover:underline text-sm text-gray-400">{t('footer.legal')}</Link>
                      <span className="hidden sm:flex">-</span>
                      <Link to={`/${i18n.resolvedLanguage}/privacy-policy`} className="hover:underline text-sm text-gray-400">{t('footer.privacy-policy')}</Link>
                      {cookieConsent &&
                          <>
                              <span className="hidden sm:flex">-</span>
                              <div className="text-sm text-gray-400">
                                  <a className="cursor-pointer hover:underline" onClick={resetCookies}>{t('footer.reset-cookies')}</a>
                              </div>
                          </>
                      }
                  </div>
              </div>

          </div>

      </footer>
  );
}

export default Footer;
