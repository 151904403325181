import React, {useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {getSupportedLanguages} from '../../configuration/i18n/i18n';

const DropdownLanguages = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const [showLanguages, setLanguages] = useState(false);
  const [languages] = useState<string[]>(getSupportedLanguages());
  const handleClick = () => {
      setLanguages(!showLanguages);
  }
  const onClickLanguage = (language: string) => {
    const currentLanguage = i18n.resolvedLanguage;
    i18n.changeLanguage(language).then(() => {
      navigate(location.pathname.replace(currentLanguage, language));
      handleClick();
    });
  }

  return (
      <div className="relative inline-block text-left">
          {languages && languages.map(language => {
              if(i18n.resolvedLanguage !== language) {
                  return <span key={language} onClick={() => onClickLanguage(language)} className="flex flew-row items-center text-gray-700 block py-2 text-sm cursor-pointer" role="menuitem" id="menu-item-0">
                                    <img className="h-6 w-6 mr-2" src={`${process.env.PUBLIC_URL}/images/flags/flag_${language === 'es' ? 'es' : 'en'}.png`}
                                         alt={`${language === 'es' ? 'español' : 'english'}`} />
                                </span>;
              }
          })}
      </div>
  );
}

export default DropdownLanguages;
