import React from 'react';
import {useTranslation} from "react-i18next";
import parse from 'html-react-parser';
import {Helmet, HelmetProvider} from "react-helmet-async";
import {getSupportedLanguages} from "../../configuration/i18n/i18n";

const LegalView = () => {
  const {t} = useTranslation();
  const currentURL = window.location.href;
  const segments = currentURL.split('/');

  return (
      <HelmetProvider>
        <Helmet>
          <title>{t('seo.legal-title')}</title>
          <meta name='robots' content='noindex,nofollow' />
          <link rel='canonical' href={currentURL} />
          {getSupportedLanguages().map(lang => {
              return <link key={lang} rel="alternate" hrefLang={lang} href={`https://www.reesman.es/${lang}/${segments[4]}`} />
          })}
        </Helmet>
        <section className="relative items-center justify-center w-full">
          <div className="max-w-6xl mx-auto">
              <div className="flex justify-center items-center title-sections mt-32 lg:mt-48">
                  <h1 className="text-header-gradient font-light text-gray-900 text-4xl text-center leading-normal p-2 lg:p-0">
                      {parse(t('legal.title'))}
                  </h1>
              </div>
          </div>
        </section>
        <div className="max-w-6xl mx-auto">
            <div className="text-justify mt-6 px-2 md:px-0">
                { parse(t('legal.text')) }
            </div>
        </div>
      </HelmetProvider>
  );
}

export default LegalView;
