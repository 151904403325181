import React from 'react';
import SectionContact from "../../components/section-contact/SectionContact";
import {Helmet, HelmetProvider} from "react-helmet-async";
import {useTranslation} from "react-i18next";
import {getSupportedLanguages} from "../../configuration/i18n/i18n";

const RegisterPropertyView = () => {
  const {t} = useTranslation();
  const currentURL = window.location.href;
  const segments = currentURL.split('/');

  return (
      <HelmetProvider>
          <Helmet>
              <title>{t('seo.contact-title')}</title>
              <meta name='description' content={t('seo.contact-description')} />
              <meta name='robots' content='index,follow' />
              <link rel='canonical' href={currentURL} />
              {getSupportedLanguages().map(lang => {
                  return <link key={lang} rel="alternate" hrefLang={lang} href={`https://www.reesman.es/${lang}/${segments[4]}`} />
              })}
          </Helmet>
          <div className="max-w-6xl mx-auto mt-32 lg:mt-48">
              <SectionContact />
          </div>
      </HelmetProvider>
  );
}

export default RegisterPropertyView;
