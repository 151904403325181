import React, {useEffect, useMemo, useState} from 'react';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {PropertyListItem} from './models/property-list-item';
import PropertySearchForm, {
  SearchParameters,
  searchParametersToUrlParams
} from '../../components/property-search-form/PropertySearchForm';
import PropertyListItemCard from '../../components/property-card/PropertyListItemCard';
import {FlexRowFullCenter, FlexRowFullCenterSpace4} from '../../components/layout/layout';
import {fetchProperties, Property} from '../../services/propertyService';
import SearchIcon from "../../components/search-mobile/SearchIcon";
import parse from 'html-react-parser';
import {Helmet, HelmetProvider} from "react-helmet-async";
import {getSupportedLanguages} from "../../configuration/i18n/i18n";

const FlexColFullWidthLg58 = (props: React.PropsWithChildren<any>) => {
  return (
    <div className="flex flex-col w-full max-w-6xl py-14">
      {props.children}
    </div>
  );
}

const Left = (props: React.PropsWithChildren<any>) => {
  return (
    <div className="hidden lg:inline lg:w-1/4 mt-2">
      {props.children}
    </div>
  );
}

const Right = (props: React.PropsWithChildren<any>) => {
  return (
    <div className="w-full md:w-11/12 lg:w-3/4 space-y-4">
      {props.children}
    </div>
  );
}

const propertiesToListItems = (properties: Property[], language: string): PropertyListItem[] => {
  return properties.map(property => {
    return {
      id: property.id,
      name: property.attributes.name,
      extract: property.attributes.extract[language],
      description: property.attributes.description[language],
      slug: property.attributes.slug,
      operation: property.attributes.property_operation[language],
      price: property.attributes.price,
      property_size: property.attributes.property_size,
      property_size_useful: property.attributes.property_size_useful,
      property_size_plot: property.attributes.property_size_plot,
      bedrooms: property.attributes.bedrooms,
      bathrooms: property.attributes.bathrooms,
      garages: property.attributes.garages,
      imageUrl: property.attributes.banner_image,
    };
  });
}

const PropertiesListView = () => {
  const [searchParams] = useSearchParams();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const {lang} = useParams();
  const [rawProperties, setRawProperties] = useState<Array<Property>>([]);
  const [properties, setProperties] = useState<Array<PropertyListItem>>([]);
  const currentURL = window.location.href;
  const segments = currentURL.split('/');

  const propertiesParams = useMemo(() => {
    return {
      propertyStatus: searchParams.get('propertyStatus') || null,
      propertyType: searchParams.get('propertyType') || null,
      city: searchParams.get('city') || null,
      priceFrom: searchParams.get('priceFrom') || null,
      priceTo: searchParams.get('priceTo') || null,
      rooms: searchParams.get('rooms') || null,
      propertyFeatures: searchParams.get('propertyFeatures') ? searchParams.get('propertyFeatures')?.split(',').filter(feature => feature !== '') : null,
      zones: searchParams.get('zones') ? searchParams.get('zones')?.split(',') : null,
    };
  }, [searchParams]);

  useEffect(() => {
    fetchProperties(propertiesParams)
      .then(setRawProperties);
  }, [propertiesParams]);

  useEffect(() => {
    setProperties(propertiesToListItems(rawProperties, i18n.resolvedLanguage));
  }, [rawProperties, i18n.resolvedLanguage]);

  const onSearch = (searchParameters: SearchParameters) => {
    const propertiesQueryParams = searchParametersToUrlParams(searchParameters);
    navigate(`/${lang}/properties?${propertiesQueryParams.toString()}`);
  }

  return (
    <HelmetProvider>
      <Helmet>
        <title>{t('seo.properties-list-title')}</title>
        <meta name='description' content={t('seo.properties-list-description')} />
        <meta name='robots' content='index,follow' />
        <link rel='canonical' href={currentURL} />
        {getSupportedLanguages().map(lang => {
          return <link key={lang} rel="alternate" hrefLang={lang} href={`https://www.reesman.es/${lang}/${segments[4]}`} />
        })}
      </Helmet>
      <section className="relative items-center justify-center w-full">
        <div className="max-w-6xl mx-auto">
          <div className="flex justify-center items-center title-sections mt-32 lg:mt-48">
            <h1 className="text-4xl font-light leading-normal text-center text-gray-900 text-header-gradient">
              {parse(t('titles-views.properties-list'))}
            </h1>
          </div>
        </div>
      </section>
      <FlexRowFullCenter>
        <FlexColFullWidthLg58>
          <FlexRowFullCenterSpace4>
            <Left>
              <PropertySearchForm isHorizontal={false} initialState={propertiesParams} onSearch={onSearch} />
            </Left>
            <Right>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-3 gap-y-5">
                {properties.map(property =>
                  <PropertyListItemCard key={property.id}
                                        property={property} />
                )}
              </div>
            </Right>
          </FlexRowFullCenterSpace4>
        </FlexColFullWidthLg58>
      </FlexRowFullCenter>
      <SearchIcon />
    </HelmetProvider>
  );
}

export default PropertiesListView;
