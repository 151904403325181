import React, {useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {DEFAULT_LANG, DEFAULT_ROUTE, I18N_DEFAULT_NAMESPACE} from '../../constants';

interface ViewNavigatorProps {
  view: React.ReactNode;
}

const ViewNavigator = ({view}: ViewNavigatorProps) => {
  const navigate = useNavigate();
  const {lang} = useParams();
  const {i18n} = useTranslation();

  useEffect(() => {
    if (lang && !i18n.hasResourceBundle(lang, I18N_DEFAULT_NAMESPACE)) {
      if (i18n.resolvedLanguage) {
        i18n.changeLanguage(i18n.resolvedLanguage)
          .then(() => navigate(`/${i18n.resolvedLanguage}/404`));
      } else {
        i18n.changeLanguage(DEFAULT_LANG)
          .then(() => navigate(DEFAULT_ROUTE));
      }
    }
  }, [navigate, lang, i18n]);

  return (
    <>
      {view}
    </>
  );
}

export default ViewNavigator;
