import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Select, {components} from 'react-select';
// import makeAnimated from 'react-select/animated';
import {
    CityAttributes,
    Data,
    getCities,
    getPropertiesStatus,
    getPropertiesTypes,
    getPropertyFeatures,
    getZones,
    PropertyFeatureAttributes,
    PropertyStatusAttributes,
    PropertyTypeAttributes,
    ZoneAttributes
} from '../../services/dataService';

interface FormDataElement {
    key: string;
    value: string;
}

interface PropertySearchFormProps {
    isHorizontal?: boolean;
    onSearch?: (searchParameters: SearchParameters) => void;
    initialState?: SearchParameters;
}

export interface SearchParameters {
    propertyStatus?: null | string;
    propertyType?: null | string;
    city?: null | string;
    zones?: null | string[];
    propertyFeatures?: null | string[];
    priceFrom?: null | string;
    priceTo?: null | string;
    rooms?: null | string;
}

export const searchParametersToUrlParams = (searchParameters: SearchParameters): URLSearchParams => {
    const propertiesQueryParams = new URLSearchParams();

    if (searchParameters.propertyStatus) {
        propertiesQueryParams.append('propertyStatus', searchParameters.propertyStatus);
    }

    if (searchParameters.propertyType) {
        propertiesQueryParams.append('propertyType', searchParameters.propertyType);
    }

    if (searchParameters.city) {
        propertiesQueryParams.append('city', searchParameters.city);
    }

    if (searchParameters.zones && searchParameters.zones.length > 0) {
        propertiesQueryParams.append('zones', searchParameters.zones.join(','));
    }

    if (searchParameters.priceFrom) {
        propertiesQueryParams.append('priceFrom', searchParameters.priceFrom);
    }

    if (searchParameters.priceTo) {
        propertiesQueryParams.append('priceTo', searchParameters.priceTo.toString());
    }

    if (searchParameters.propertyFeatures && searchParameters.propertyFeatures.length > 0) {
        propertiesQueryParams.append('propertyFeatures', searchParameters.propertyFeatures.join(','));
    }

    if (searchParameters.rooms) {
        propertiesQueryParams.append('rooms', searchParameters.rooms.toString());
    }

    return propertiesQueryParams;
}

const convertPropertyStatus = (list: Data<PropertyStatusAttributes>[], currentLang: string, t:any): FormDataElement[] => {
    const result = list.map(propertyStatus => {
        let translatedValue = null;

        for (const translation of propertyStatus.attributes.translations) {
            if (translation.language === currentLang) {
                translatedValue = translation.value;
                break;
            }
        }

        if (!translatedValue) {
            translatedValue = propertyStatus.attributes.slug;
        }

        return {
            key: propertyStatus.attributes.slug,
            value: translatedValue,
        };
    });

    result.sort((a: FormDataElement, b: FormDataElement) => a.value.localeCompare(b.value));

    return [{ key: '', value: `${t('search-form.select')}` }, ...result];
}

const convertPropertyType = (list: Data<PropertyTypeAttributes>[], currentLang: string, t:any): FormDataElement[] => {
    const result = list.map(propertyType => {
        let translatedValue = null;

        for (const translation of propertyType.attributes.translations) {
            if (translation.language === currentLang) {
                translatedValue = translation.value;
                break;
            }
        }

        if (!translatedValue) {
            translatedValue = propertyType.attributes.slug;
        }

        return {
            key: propertyType.attributes.slug,
            value: translatedValue,
        };
    });

    result.sort((a: FormDataElement, b: FormDataElement) => a.value.localeCompare(b.value));

    return [{ key: '', value: `${t('search-form.select')}` }, ...result];
}

const convertCity = (list: Data<CityAttributes>[], t:any): FormDataElement[] => {
    const result = list.map(city => {
        return {
            key: city.id,
            value: city.attributes.name,
        };
    });

    result.sort((a: FormDataElement, b: FormDataElement) => a.value.localeCompare(b.value));

    return [{ key: '', value: `${t('search-form.select')}` }, ...result];
}

const convertZone = (list: Data<ZoneAttributes>[], t:any): FormDataElement[] => {
    const result = list.map(zone => {
        return {
            key: zone.attributes.slug,
            value: zone.attributes.name,
        };
    });

    result.sort((a: FormDataElement, b: FormDataElement) => a.value.localeCompare(b.value));

    return [{ key: '', value: `${t('search-form.select')}` }, ...result];
}

const convertPropertyFeature = (list: Data<PropertyFeatureAttributes>[], currentLang: string, t:any): FormDataElement[] => {
    const result = list.map(propertyFeature => {
        let translatedValue = null;

        for (const translation of propertyFeature.attributes.translations) {
            if (translation.language === currentLang) {
                translatedValue = translation.value;
                break;
            }
        }

        if (!translatedValue) {
            translatedValue = propertyFeature.attributes.slug;
        }

        return {
            key: propertyFeature.attributes.slug,
            value: translatedValue,
        };
    });

    result.sort((a: FormDataElement, b: FormDataElement) => a.value.localeCompare(b.value));

    return [{ key: '', value: `${t('search-form.select')}` }, ...result];
}

const SearchForm = (props:PropertySearchFormProps) => {
    const {t, i18n} = useTranslation();

    const initialState = props.initialState || {};

    const [selectedPropertyStatus, setSelectedPropertyStatus] = useState<string>(initialState.propertyStatus || '');
    const [selectedPropertyType, setSelectedPropertyType] = useState<string>(initialState.propertyType || '');
    const [selectedCity, setSelectedCity] = useState<string>(initialState.city || '');
    const [selectedZones, setSelectedZones] = useState<string[]>(initialState.zones || []);
    const [selectedPropertyFeature, setSelectedPropertyFeature] = useState<string[]>(initialState.propertyFeatures || []);
    const [selectedPriceFrom, setSelectedPriceFrom] = useState<string>(initialState.priceFrom || '');
    const [selectedPriceTo, setSelectedPriceTo] = useState<string>(initialState.priceTo || '');
    const [selectedRooms, setSelectedRooms] = useState<string>(initialState.rooms || '');

    const [propertyStatusList, setPropertyStatusList] = useState<Data<PropertyStatusAttributes>[]>([]);
    const [propertyTypeList, setPropertyTypeList] = useState<Data<PropertyTypeAttributes>[]>([]);
    const [cityList, setCityList] = useState<Data<CityAttributes>[]>([]);
    const [zoneList, setZoneList] = useState<Data<ZoneAttributes>[]>([]);
    const [propertyFeatureList, setPropertyFeatureList] = useState<Data<PropertyFeatureAttributes>[]>([]);
    const [propertyStatusFormData, setPropertyStatusFormData] = useState<FormDataElement[]>([]);
    const [propertyTypeFormData, setPropertyTypeFormData] = useState<FormDataElement[]>([]);
    const [cityFormData, setCityFormData] = useState<FormDataElement[]>([]);
    const [zoneFormData, setZoneFormData] = useState<FormDataElement[]>([]);
    const [propertyFeatureFormData, setPropertyFeatureFormData] = useState<FormDataElement[]>([]);

    // const animatedComponents = makeAnimated();
    const onSearch = props.onSearch || (() => {});

    const customStyles = {
        option: (provided:any, state:any) => ({
            ...provided,
            borderBottom: '1px solid #e5e7eb',
            color: state.isDisabled ? '#d1d5db' : state.selectProps.menuColor,
            padding: 10,
            fontSize: '0.875rem',
            cursor: state.isDisabled ? 'not-allowed' : 'pointer',
            backgroundColor: state.isSelected ? '#bfdbfe' : ''
        }),
        control: (provided:any, state:any) => ({
            ...provided,
            // none of react-select's styles are passed to <Control />
            display: 'flex',
            width: '100%',
            maxWidth: '100%',
            lineHeight: '1.25rem',
            transitionDuration: '150ms',
            transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
            boxShadow: '0 1px 2px 0 rgb(0 0 0 / 0.05)',
            backgroundColor: state.isDisabled ? 'rgb(230, 230, 230)' : 'rgb(255 255 255)',
            opacity: 1,
            border: '1px solid rgb(209 213 219)',
            borderRadius: '0.375rem',
            textOverflow: 'ellipsis',
            fontSize: props.isHorizontal ? '0.875rem' : '0.875rem',
            "@media (min-width: 640px)": {
                width: "100%",
                maxWidth: "100%"
            },
            "@media (min-width: 768px)": {
                width: '100%',
                maxWidth: '100%',
            },
            "@media (min-width: 1024px)": {
                width: '10rem',
                maxWidth: '10rem',
            },
            "@media (min-width: 1280px)": {
                width: '10rem',
                maxWidth: '10rem',
            },
        }),
        singleValue: (provided:any, state:any) => {
            const opacity = state.isDisabled ? 0.5 : 1;
            const transition = 'opacity 300ms';

            return { ...provided, opacity, transition };
        },
        valueContainer: (provided:any, state:any) => ({
            ...provided,
            flexWrap: 'nowrap',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'truncate'
        }),
        // valueContainer: (css:any) => ({ ...css, "flex-wrap": "nowrap", "white-space": "nowrap", overflow: "hidden", 'text-overflow': 'ellipsis' })
    }

    // Load data only once
    useEffect(() => {
        getPropertiesStatus()
          .then(setPropertyStatusList);

        getPropertiesTypes()
          .then(setPropertyTypeList);

        getCities()
          .then(setCityList);

        getPropertyFeatures()
          .then(setPropertyFeatureList);
    }, []);

    // Callback when language or data changes
    useEffect(() => {
        Promise.resolve(propertyStatusList)
          .then(list => convertPropertyStatus(list, i18n.resolvedLanguage, t))
          .then(setPropertyStatusFormData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n.resolvedLanguage, propertyStatusList]);

    // Callback when language or data changes
    useEffect(() => {
        Promise.resolve(propertyTypeList)
          .then(list => convertPropertyType(list, i18n.resolvedLanguage, t))
          .then(setPropertyTypeFormData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n.resolvedLanguage, propertyTypeList]);

    // Callback when data changes
    useEffect(() => {
        Promise.resolve(cityList)
          .then(list => convertCity(list, t))
          .then(setCityFormData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cityList]);

    // Callback when data changes
    useEffect(() => {
        Promise.resolve(zoneList)
          .then(list => convertZone(list, t))
          .then(setZoneFormData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [zoneList]);

    // Callback when language or data changes
    useEffect(() => {
        Promise.resolve(propertyFeatureList)
          .then(list => convertPropertyFeature(list, i18n.resolvedLanguage, t))
          .then(setPropertyFeatureFormData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n.resolvedLanguage, propertyFeatureList]);

    // Callback when selectedCity changes
    useEffect(() => {
        getZones(selectedCity)
          .then(setZoneList);
    }, [selectedCity]);

    const onSearchClick = () => {
        onSearch({
            propertyStatus: selectedPropertyStatus,
            propertyType: selectedPropertyType,
            city: selectedCity,
            zones: selectedZones,
            propertyFeatures: selectedPropertyFeature,
            priceFrom: selectedPriceFrom,
            priceTo: selectedPriceTo,
            rooms: selectedRooms,
        });
    }

    return (
      <div id="search-properties" className={`fixed inset-0 z-40 overflow-y-auto bg-gray-100`}>
          <div className="w-full flex flex-col justify-center min-h-screen space-y-2 py-6 px-2">
              <div className='m-2'>
                  <label className="block text-sm leading-5 font-medium text-gray-700">
                      {t('search-form.operation')}
                  </label>
                  <select id="operation" name="operation" className={props.isHorizontal ? `truncate block form-select w-full sm:w-40 md:w-28 lg:w-32 sm:text-sm sm:leading-5 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring focus:ring-1 focus:ring-blue-100 transition duration-150 ease-in-out` :
                    `truncate block form-select w-full sm:text-sm sm:leading-5 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring focus:ring-1 focus:ring-blue-100 transition duration-150 ease-in-out`}
                          value={selectedPropertyStatus}
                          onChange={(event) => setSelectedPropertyStatus(event.target.value)}>
                      {propertyStatusFormData.map(element => (
                        <option key={element.key}
                                value={element.key}>{element.value}</option>
                      ))}
                  </select>
              </div>
              <div className='m-2'>
                  <label className="block text-sm leading-5 font-medium text-gray-700">
                      {t('search-form.type')}
                  </label>
                  <select id="type" name="type" className={props.isHorizontal ? `truncate block form-select w-full sm:w-40 md:w-28 lg:w-32 sm:text-sm sm:leading-5 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring focus:ring-1 focus:ring-blue-100 transition duration-150 ease-in-out` :
                    `truncate block form-select w-full sm:text-sm sm:leading-5 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring focus:ring-1 focus:ring-blue-100 transition duration-150 ease-in-out`}
                          value={selectedPropertyType}
                          onChange={(event) => setSelectedPropertyType(event.target.value)}>
                      {propertyTypeFormData.map(element => (
                        <option key={element.key}
                                value={element.key}>{element.value}</option>
                      ))}
                  </select>
              </div>
              <div className='m-2'>
                  <label className="block text-sm leading-5 font-medium text-gray-700">
                      {t('search-form.region')}
                  </label>
                  <select id="region" name="region" className={props.isHorizontal ? `truncate block form-select w-full sm:w-40 md:w-28 lg:w-32 sm:text-sm sm:leading-5 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring focus:ring-1 focus:ring-blue-100 transition duration-150 ease-in-out` :
                    `truncate block form-select w-full sm:text-sm sm:leading-5 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring focus:ring-1 focus:ring-blue-100 transition duration-150 ease-in-out`}
                          value={selectedCity}
                          onChange={(event) => setSelectedCity(event.target.value)}>
                      {cityFormData.map(element => (
                        <option key={element.key}
                                value={element.key}>{element.value}</option>
                      ))}
                  </select>
              </div>
              <div className='m-2'>
                  <label className="block text-sm leading-5 font-medium text-gray-700">
                      {t('search-form.town')}
                  </label>
                  <Select options={zoneFormData}
                          getOptionValue={(option) => `${option.key}`}
                          getOptionLabel={(option) => `${option.value}`}
                          onChange={newValue => setSelectedZones(newValue.map(option => option.key))}
                          value={zoneFormData.filter(zone => selectedZones.includes(zone.key))}
                          isMulti={true}
                          components={{ValueContainer}}
                          styles={customStyles}
                          placeholder={`${t('search-form.select')}`}
                          classNamePrefix="input-multiple"
                          hideSelectedOptions={false}
                          isClearable
                          isSearchable
                          isOptionDisabled={(option) => option.value === `${t('search-form.select')}`}
                          isDisabled={selectedCity === null || selectedCity === ''}
                          closeMenuOnSelect={false} />
              </div>
              <div className='m-2'>
                  <label className="block text-sm leading-5 font-medium text-gray-700">
                      {t('search-form.price')}
                  </label>
                  <div className="flex items-center justify-start">
                      <input type="text" placeholder={`${t('search-form.from')}`} className={props.isHorizontal ? `form-input block w-full sm:w-24 sm:text-sm sm:leading-5 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring focus:ring-1 focus:ring-blue-100 transition duration-150 ease-in-out` :
                        `form-input block w-full sm:text-sm sm:leading-5 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring focus:ring-1 focus:ring-blue-100 transition duration-150 ease-in-out`}
                             value={selectedPriceFrom}
                             onChange={e => setSelectedPriceFrom(e.target.value)} />
                      <input type="text" placeholder={`${t('search-form.until')}`} className={props.isHorizontal ? `form-input block w-full sm:w-24 ml-1 sm:text-sm sm:leading-5 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring focus:ring-1 focus:ring-blue-100 transition duration-150 ease-in-out` :
                        `form-input block w-full ml-1 sm:text-sm sm:leading-5 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring focus:ring-1 focus:ring-blue-100 transition duration-150 ease-in-out`}
                             value={selectedPriceTo}
                             onChange={e => setSelectedPriceTo(e.target.value)} />
                  </div>
              </div>
              {!props.isHorizontal &&
                <div className='m-2'>
                    <label className="block text-sm leading-5 font-medium text-gray-700">
                        {t('search-form.bedrooms')}
                    </label>
                    <input type="number" className="form-input block w-full sm:text-sm sm:leading-5 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring focus:ring-1 focus:ring-blue-100 transition duration-150 ease-in-out"
                           value={selectedRooms}
                           onChange={e => setSelectedRooms(e.target.value)}/>
                </div>
              }
              {!props.isHorizontal &&
                <div className='m-2'>
                    <label className="block text-sm leading-5 font-medium text-gray-700">
                        {t('search-form.reference')}
                    </label>
                    <input type="text" className="form-input block w-full sm:text-sm sm:leading-5 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring focus:ring-1 focus:ring-blue-100 transition duration-150 ease-in-out" />
                </div>
              }
              <div className='m-2'>
                  <label className="block text-sm leading-5 font-medium text-gray-700">
                      {t('search-form.features')}
                  </label>
                  <Select options={propertyFeatureFormData}
                          getOptionValue={(option) => `${option.key}`}
                          getOptionLabel={(option) => `${option.value}`}
                          onChange={newValue => setSelectedPropertyFeature(newValue.map(option => option.key))}
                          value={propertyFeatureFormData.filter(feature => selectedPropertyFeature.includes(feature.key))}
                          isMulti={true}
                          components={{ValueContainer}}
                          styles={customStyles}
                          placeholder={`${t('search-form.select')}`}
                          classNamePrefix="input-multiple"
                          hideSelectedOptions={false}
                          isClearable
                          isSearchable
                          isOptionDisabled={(option) => option.value === `${t('search-form.select')}`}
                          closeMenuOnSelect={false} />
              </div>
              <div className='m-2 text-center'>
                  <button className='w-full text-white bg-gray-800 hover:bg-gray-900 mt-4 font-bold py-2 px-4 border border-gray-800 rounded-lg focus:outline-none'
                          onClick={onSearchClick}>
                      {`${t('buttons.search')}`}
                  </button>
              </div>
          </div>
      </div>
    );
}

//Esta fuera del componente para que al hacer click fuera se cierre el contenedor
const ValueContainer = ({children, ...props}: any) => {
    const {t, i18n} = useTranslation();
    let [values, input] = children as any;

    if (Array.isArray(values)) {
        const plural = values.length === 1 ? "" : "s";
        values = `${values.length} ${t('multi-select.item')}${plural} ${t('multi-select.selected')}${plural && i18n.resolvedLanguage === 'es' ? 's' : ''}`;
    }

    return (
        <components.ValueContainer {...props}>
            {values}
            {input}
        </components.ValueContainer>
    );
};

export default SearchForm;
