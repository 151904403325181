import React from 'react';
import Slider from 'react-slick';
import {useTranslation} from 'react-i18next';
import {Link, useNavigate, useParams} from "react-router-dom";
import PropertySearchForm, {
    SearchParameters,
    searchParametersToUrlParams
} from "../property-search-form/PropertySearchForm";

interface BannerProps {
    autoplay?: boolean,
    autoplaySpeed?: number,
    centerMode?: boolean,
    speed?: number,
    variableWidth?: boolean,
    vertical?: boolean,
    infinite?: boolean,
    slidesToShow?: number,
    slidesToScroll?: number,
    pauseOnHover?: boolean,
    dots?: boolean,
    pauseOnDotsHover?: boolean,
    cssEase?: string,
    fade?: boolean,
    draggable?: boolean,
    arrows?: boolean,
    zIndex?: number,
}

const Banner = (settings: BannerProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {lang} = useParams();

  const onSearch = (searchParameters: SearchParameters) => {
    const propertiesQueryParams = searchParametersToUrlParams(searchParameters);
    navigate(`/${lang}/properties?${propertiesQueryParams.toString()}`);
  }

  return (
    <div>
      <div className="topBanner__container">
        <div className="carousel__container">
            <Slider {...settings}>
                <div>
                    <div className="">
                        <div className="background">
                            <div className="background__inner topBanner__background-inner-position" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/can_beach.jpg)` }}>

                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="">
                        <div className="background">
                            <div className="background__inner topBanner__background-inner-position" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/reesman_office.jpg)` }}>

                            </div>
                        </div>
                    </div>
                </div>
            </Slider>
        </div>
      </div>
      <div className="relative" data-aos={"fade-left"}>
          <div className="absolute inset-x-0 md:max-w-6xl md:mx-auto text-left px-2 max-w-title -mt-28 lg:-mt-64">
              <h1 className="text-4xl lg:text-5xl font-bold text-white">{t('banner.title')}</h1>
          </div>
      </div>
      <div className="relative">
        <PropertySearchForm isHorizontal={true} onSearch={onSearch} />
      </div>
    </div>
  );
}

export default Banner;
