import React from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from "react-router-dom";
import parse from 'html-react-parser';

const SectionLegalHome = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.resolvedLanguage;

  return (
      <section className="bg-white py-12 md:py-24 xl:relative">

          <div className="max-w-screen-xl mx-auto px-4">
              <div data-aos={"fade-up"} className="flex flex-col gap-y-3 justify-center items-center w-full mb-8 md:mb-14">
                  <h2 className="text-header-gradient font-light text-gray-900 text-4xl text-center lg:text-left leading-normal">
                      {parse(t('titles-sections.legal-and-real-state-services'))}
                  </h2>
                  <span
                      className="text-xl text-justify md:text-center text-gray-400">{parse(t('titles-sections.legal-and-real-state-services-subtitle'))}</span>
              </div>
              <div className="flex flex-col gap-y-8 lg:flex-row lg:justify-center lg:items-center lg:gap-x-6">

                  <div className="w-full lg:w-1/2 z-10 flex justify-center items-center" data-aos={"fade-right"}>
                      <img className="rounded-md md:max-w-lg lg:max-w-xl" src={`${process.env.PUBLIC_URL}/images/man_sign.jpg`}
                           alt="Feature img"/>
                  </div>

                  <div data-aos={"fade-up"} className="w-full lg:w-1/2 flex justify-center items-center">
                      <ul>
                          <li className="flex flex-row items-center gap-x-2 font-semibold text-gray-900 text-lg md:text-2xl mb-3.5">
                              <span
                                  className="p-2 h-14 w-14 md:mx-0 bg-gray-200 rounded-full flex items-center justify-center">
                                  <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none"
                                       viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                      <path strokeLinecap="round" strokeLinejoin="round"
                                            d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"/>
                                  </svg>
                              </span>
                              {t('legal-home.property-real-estate-rights')}
                          </li>
                          <li className="flex flex-row items-center gap-x-2 font-semibold text-gray-900 text-lg md:text-2xl mb-3.5">
                              <span
                                  className="p-2 h-14 w-14 md:mx-0 bg-gray-200 rounded-full flex items-center justify-center">
                                  <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none"
                                       viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                      <path strokeLinecap="round" strokeLinejoin="round"
                                            d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"/>
                                  </svg>
                              </span>
                              {t('legal-home.lease-agreements')}
                          </li>
                          <li className="flex flex-row items-center gap-x-2 font-semibold text-gray-900 text-lg md:text-2xl mb-3.5">
                              <span
                                  className="p-2 h-14 w-14 md:mx-0 bg-gray-200 rounded-full flex items-center justify-center">
                                  <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none"
                                       viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                      <path d="M12 14l9-5-9-5-9 5 9 5z"/>
                                      <path
                                          d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z"/>
                                      <path strokeLinecap="round" strokeLinejoin="round"
                                            d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"/>
                                  </svg>
                              </span>
                              {t('legal-home.real-estate-law')}
                          </li>
                          <li className="flex flex-row items-center gap-x-2 font-semibold text-gray-900 text-lg md:text-2xl mb-3.5">
                              <span
                                  className="p-2 h-14 w-14 md:mx-0 bg-gray-200 rounded-full flex items-center justify-center">
                                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                       strokeWidth="2"
                                       stroke="currentColor" className="h-8 w-8">
                                      <path strokeLinecap="round" strokeLinejoin="round"
                                            d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0 1 18 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3 1.5 1.5 3-3.75"/>
                                  </svg>
                              </span>
                              {t('legal-home.eviction-proceedings')}
                          </li>
                          <li className="flex flex-row items-center gap-x-2 font-semibold text-gray-900 text-lg md:text-2xl mb-3.5">
                              <span
                                  className="p-2 h-14 w-14 md:mx-0 bg-gray-200 rounded-full flex items-center justify-center">
                                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                       strokeWidth="2"
                                       stroke="currentColor" className="h-8 w-8">
                                      <path strokeLinecap="round" strokeLinejoin="round"
                                            d="M14.25 7.756a4.5 4.5 0 1 0 0 8.488M7.5 10.5h5.25m-5.25 3h5.25M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                                  </svg>
                              </span>
                              {t('legal-home.property-inheritance-wills')}
                          </li>
                          <li className="flex flex-row items-center gap-x-2 font-semibold text-gray-900 text-lg md:text-2xl mb-3.5">
                              <span
                                  className="p-2 h-14 w-14 md:mx-0 bg-gray-200 rounded-full flex items-center justify-center">
                                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                       strokeWidth="2"
                                       stroke="currentColor" className="h-8 w-8">
                                      <path strokeLinecap="round" strokeLinejoin="round"
                                            d="M13.181 8.68a4.503 4.503 0 0 1 1.903 6.405m-9.768-2.782L3.56 14.06a4.5 4.5 0 0 0 6.364 6.365l3.129-3.129m5.614-5.615 1.757-1.757a4.5 4.5 0 0 0-6.364-6.365l-4.5 4.5c-.258.26-.479.541-.661.84m1.903 6.405a4.495 4.495 0 0 1-1.242-.88 4.483 4.483 0 0 1-1.062-1.683m6.587 2.345 5.907 5.907m-5.907-5.907L8.898 8.898M2.991 2.99 8.898 8.9"/>
                                  </svg>
                              </span>
                              {t('legal-home.separations-and-divorces')}
                          </li>
                          <li className="flex flex-row items-center gap-x-2 font-semibold text-gray-900 text-lg md:text-2xl">
                              <span
                                  className="p-2 h-14 w-14 md:mx-0 bg-gray-200 rounded-full flex items-center justify-center">
                                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                       strokeWidth="2"
                                       stroke="currentColor" className="h-8 w-8">
                                      <path strokeLinecap="round" strokeLinejoin="round"
                                            d="M12 3v17.25m0 0c-1.472 0-2.882.265-4.185.75M12 20.25c1.472 0 2.882.265 4.185.75M18.75 4.97A48.416 48.416 0 0 0 12 4.5c-2.291 0-4.545.16-6.75.47m13.5 0c1.01.143 2.01.317 3 .52m-3-.52 2.62 10.726c.122.499-.106 1.028-.589 1.202a5.988 5.988 0 0 1-2.031.352 5.988 5.988 0 0 1-2.031-.352c-.483-.174-.711-.703-.59-1.202L18.75 4.971Zm-16.5.52c.99-.203 1.99-.377 3-.52m0 0 2.62 10.726c.122.499-.106 1.028-.589 1.202a5.989 5.989 0 0 1-2.031.352 5.989 5.989 0 0 1-2.031-.352c-.483-.174-.711-.703-.59-1.202L5.25 4.971Z"/>
                                  </svg>
                              </span>
                              {t('legal-home.labor-law')}
                          </li>
                      </ul>
                  </div>

              </div>

              <div data-aos={"fade-up"} className="flex justify-center items-center w-full text-center mt-14">
                  <p className="text-xl">{parse(t('legal-home.more-info-contact-email'))}</p>
              </div>

          </div>
      </section>
);
}

export default SectionLegalHome;
