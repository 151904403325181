import React from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from "react-router-dom";

const SectionAdvise = () => {
  const { t } = useTranslation();

  return (
    <div className="w-full bg-advise">
      <div className="section-advise w-full h-full relative">
          <div className="max-w-6xl mx-auto py-10">
              <div className="lg:py-16 py-10 flex flex-col items-center justify-center relative">
                  <span className="text-white text-center text-2xl lg:text-3xl font-light leading-normal text-header-gradient z-10 px-10 md:px-0">{t('titles-sections.we-advise-you')}</span>
                  <Link to="/es/contact" className="rounded-full text-gray-900 bg-white button-advise text-header-gradient font-light text-md px-4 py-2 my-10">{t('buttons.contact-us')}</Link>
              </div>
          </div>
      </div>
    </div>
  );
}

export default SectionAdvise;
