import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import {Link} from "react-router-dom";

declare global {
  interface Window {
    gtag?: (
      key: string,
      trackingId: string,
      config: { analytics_storage: string, ad_storage: string }
    ) => void
  }
}

interface CookiesCardProps {
  onChange: (cookieConsent: boolean) => void;
}

const CookiesCard = ({ onChange }: CookiesCardProps) => {
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState(false);
  const [cookieConsent, setCookieConsent] = useState(false);
  const [cookieConsentDate, setCookieConsentDate] = useState<string>('');
  const [analyticsStorage, setAnalyticsStorage] = useState(false);
  const [adStorage, setAdStorage] = useState(false);
  const currentLanguage = i18n.resolvedLanguage;

  useEffect(() => {
    const localCookie = localStorage.getItem('cookieConsent');

    if (localCookie) {
      setCookieConsent(JSON.parse(localCookie));
    }

    const localCookieConsentDate = localStorage.getItem('cookieConsentDate');

    if (localCookieConsentDate) {
      setCookieConsentDate(JSON.parse(localCookieConsentDate));
    }

    const localAnalyticsStorage = localStorage.getItem('analyticsStorage');

    if (localAnalyticsStorage) {
      setAnalyticsStorage(JSON.parse(localAnalyticsStorage));
    }

    const localAdStorage = localStorage.getItem('adStorage');

    if (localAdStorage) {
      setAdStorage(JSON.parse(localAdStorage));
    }

    if (window.gtag) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      localCookie ? window.gtag('consent', 'update', {
        'analytics_storage': (localAnalyticsStorage ? 'granted' : 'denied'),
        'ad_storage': (localAdStorage ? 'granted' : 'denied')
      }) : '';
    }

    console.log(localCookie);
    setOpen(localCookie !== 'true');

    const date = new Date();
    if(localCookieConsentDate && localCookieConsentDate < moment(date).subtract(1, 'years').subtract(1, 'days').format('YYYY-MM-DD')) {
      setCookieConsent(false);
      setCookieConsentDate('');
    }

    onChange(localCookie === 'true');
  }, [onChange]);

  const acceptCookies = () => {
    const date = moment(new Date()).format('YYYY-MM-DD');
    setOpen(false);
    setCookieConsent(true);
    setCookieConsentDate(date);
    localStorage.setItem('cookieConsent', JSON.stringify(true));
    localStorage.setItem('cookieConsentDate', JSON.stringify(date));
    localStorage.setItem('analyticsStorage', JSON.stringify(analyticsStorage));
    localStorage.setItem('adStorage', JSON.stringify(adStorage));
    onChange(true);
  }

  const ignoreCookies = () => {
    setOpen(false);
    setCookieConsent(false);
    localStorage.setItem('cookieConsent', JSON.stringify(false));
    onChange(false);
  }

  const handleAnalytics = () => {
    setAnalyticsStorage(!analyticsStorage);
  }

  const handleAd = () => {
    setAdStorage(!adStorage);
  }

  return (
    <>
      {open &&
          <div
              className="fixed z-50 inset-x-4 bottom-4 md:bottom-8 md:w-80 flex flex-col p-6 space-y-4 bg-white border border-neutral/10 rounded shadow-lg md:right-auto md:left-8">
              <h2 className="text-2xl font-bold">{t('cookies.title')} 🍪</h2>
              <p className="text-sm font-bold text-neutral">
                {t('cookies.subtitle')}
                  <Link className="underline hover:text-primary" to={`/${currentLanguage}/privacy-policy`}>{t('cookies.learn_more_cookies')}</Link>
              </p>
              <div className="mt-4 flex flex-col space-y-2">
                  <label className="inline-flex items-center">
                      <input
                          className="mr-3 w-5 h-5 rounded border-neutral text-gray-800 focus:ring-gray-600 focus-visible:ring focus-visible:ring-primary motion-safe:transition opacity-50"
                          type="checkbox" name="functional" checked={true} disabled={true}/>
                      <span className="flex flex-col">
                          <span className="font-bold text-xs">{t('cookies.functional_cookies')}</span>
                          <span className="text-xs text-neutral/75">{t('cookies.text_functional_cookies')}</span>
                      </span>
                  </label>

                  <label className="inline-flex items-center">
                      <input
                          className="mr-3 w-5 h-5 rounded border-neutral text-gray-800 focus:ring-gray-600 focus-visible:ring focus-visible:ring-primary motion-safe:transition"
                          type="checkbox" name="analytics_storage" onChange={handleAnalytics}/>
                      <span className="flex flex-col">
                          <span className="font-bold text-xs">{t('cookies.analytics_cookies')}</span>
                          <span className="text-xs text-neutral/75">{t('cookies.text_analytics_cookies')}</span>
                      </span>
                  </label>

                  <label className="inline-flex items-center">
                      <input
                          className="mr-3 w-5 h-5 rounded border-neutral text-gray-800 focus:ring-gray-600 focus-visible:ring focus-visible:ring-primary motion-safe:transition"
                          type="checkbox" name="ad_storage" onChange={handleAd}/>
                      <span className="flex flex-col">
                          <span className="font-bold text-xs">{t('cookies.adds_cookies')}</span>
                          <span className="text-xs text-neutral/75">{t('cookies.text_adds_cookies')}</span>
                      </span>
                  </label>
              </div>
              <div className="flex space-x-4">
                  <button onClick={acceptCookies} type="button"
                          className="px-4 py-2 bg-black rounded text-sm font-bold text-white focus:outline-none focus-visible:ring focus-visible:ring-offset-2 focus-visible:ring-primary motion-safe:transition">
                    {t('cookies.accept_cookies')}
                  </button>
                  <button onClick={ignoreCookies}
                          className="px-1 py-2 rounded text-sm font-bold text-neutral focus:outline-none focus-visible:ring focus-visible:ring-offset-2 focus-visible:ring-primary motion-safe:transition">
                    {t('cookies.ignore_cookies')}
                  </button>
              </div>
          </div>
      }
    </>
  );
}

export default CookiesCard;
