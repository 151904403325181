import React from 'react';
import classNames from 'classnames';
import useScrollPosition from "../../hooks/useScrollPosition";

interface CommonProps {
  hidden?: boolean,
}

export const FlexRowFullCenterFixedNav = (props: React.PropsWithChildren<CommonProps>) => {
  const scrollPosition = useScrollPosition();

  return (
      <div className={classNames('fixed', 'w-full', 'z-30', 'md:bg-opacity-90 bg-black shadow-lg', 'transition', 'duration-300', 'ease-in-out', { hidden: props.hidden })}>
        {props.children}
      </div>
  );
}

export const FlexRowFullCenter = (props: React.PropsWithChildren<CommonProps>) => {
  return (
    <div className={classNames('flex', 'flex-row', 'w-full', 'justify-center', { hidden: props.hidden })}>
      {props.children}
    </div>
  );
}

export const FlexRowFullCenterSpace4 = (props: React.PropsWithChildren<any>) => {
  return (
    <div className="flex flex-row w-full justify-center md:space-x-4 p-2">
      {props.children}
    </div>
  );
}
