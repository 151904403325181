import {createSlice, PayloadAction} from '@reduxjs/toolkit';

interface AppState {
  currentPropertyId: null | number,
}

const initialState = () => {
  return {
    currentPropertyId: null,
  } as AppState;
}

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    changeCurrentPropertyId(state, action: PayloadAction<number>) {
      state.currentPropertyId = action.payload;
    },
  },
})

export const { changeCurrentPropertyId } = appSlice.actions;
export default appSlice.reducer;
