import React from 'react';
import {Helmet, HelmetProvider} from "react-helmet-async";
import {useTranslation} from "react-i18next";
import {getSupportedLanguages} from "../../configuration/i18n/i18n";
import parse from "html-react-parser";
import {Link} from "react-router-dom";

const NotFoundView = () => {
  const {t} = useTranslation();
  const currentURL = window.location.href;
  const segments = currentURL.split('/');

  return (
      <HelmetProvider>
          <Helmet>
              <title>{t('seo.not-found-title')}</title>
              <meta name='robots' content='noindex,nofollow' />
              <link rel='canonical' href={currentURL} />
              {getSupportedLanguages().map(lang => {
                  return <link key={lang} rel="alternate" hrefLang={lang} href={`https://www.reesman.es/${lang}/${segments[4]}`} />
              })}
          </Helmet>
          <section className="relative items-center justify-center w-full">
              <div className="max-w-6xl mx-auto">
                  <div className="flex justify-center items-center title-sections mt-32 lg:mt-48">
                      <h1 className="text-header-gradient font-light text-gray-900 text-4xl text-center leading-normal p-2 lg:p-0">
                          {parse(t('titles-views.not-found'))}
                      </h1>
                  </div>
              </div>
          </section>

          <section className="pt-24 pb-20">
              <div className="flex items-center justify-center">
                  <div className="px-20 py-20">
                      <div className="flex flex-col items-center">
                          <h1 className="font-bold title__title text-4xl">404</h1>

                          <h6 className="mb-2 text-2xl font-bold text-center text-gray-800 md:text-3xl">
                              <span>{t('not-found.sorry')}</span> {t('not-found.page-not-found')}
                          </h6>

                          <p className="mb-8 text-center text-gray-500 md:text-lg">
                              {t('not-found.description')}
                          </p>

                          <Link to="/"
                             className="w-32 py-4 text-center font-semibold text-lg text-white bg-gray-700 rounded-xl hover:bg-gray-900 transition ease-in-out duration-500">
                              <span>{t('not-found.go-home')}</span>
                          </Link>
                      </div>
                  </div>
              </div>
          </section>
      </HelmetProvider>
  );
}

export default NotFoundView;
