import React from 'react';
import {Route, Routes, Navigate} from 'react-router-dom';
import HomeView from '../../views/home/HomeView';
import AboutView from '../../views/about/AboutView';
import PropertiesListView from '../../views/properties/PropertiesListView';
import PropertyDetailView from '../../views/properties/PropertyDetailView';
import RootNavigator from './RootNavigator';
import ViewNavigator from './ViewNavigator';
import RegisterPropertyView from '../../views/register-property/RegisterPropertyView';
import ContactView from '../../views/contact/ContactView';
import ServicesView from '../../views/services/ServicesView';
import NotFoundView from "../../views/not-found-view/NotFoundView";
import PrivacyPolicyView from "../../views/privacy-policy/PrivacyPolicyView";
import LegalView from "../../views/legal/LegalView";

const Routing = () => {
  return (
    <Routes>
      <Route path="/" element={<RootNavigator />} />
      <Route path="/:lang" element={<RootNavigator />} />
      <Route path="/:lang/home" element={<ViewNavigator view={<HomeView />} />} />
      <Route path="/:lang/properties" element={<ViewNavigator view={<PropertiesListView key={Math.random()} />} />} />
      <Route path="/:lang/properties/:year/:month/:day/:slug" element={<ViewNavigator view={<PropertyDetailView />} />} />
      <Route path="/:lang/properties/:slug" element={<ViewNavigator view={<PropertyDetailView />} />} />
      <Route path="/:lang/property/:id" element={<ViewNavigator view={<PropertyDetailView />} />} />
      <Route path="/:lang/register-property" element={<ViewNavigator view={<RegisterPropertyView />} />} />
      <Route path="/:lang/contact" element={<ViewNavigator view={<ContactView />} />} />
      <Route path="/:lang/about" element={<ViewNavigator view={<AboutView />} />} />
      <Route path="/:lang/privacy-policy" element={<ViewNavigator view={<PrivacyPolicyView />} />} />
      <Route path="/:lang/legal" element={<ViewNavigator view={<LegalView />} />} />
      <Route path="/:lang/404" element={<ViewNavigator view={<NotFoundView />} />} />
      <Route path="*" element={<Navigate replace to="/:lang/404" />} />
    </Routes>
  );
}

export default Routing;
