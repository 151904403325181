import React, {useState} from 'react';
import Slider from 'react-slick';
import {useTranslation} from 'react-i18next';
import {Property} from "../../services/propertyService";
import {PropertyListItem} from "../../views/properties/models/property-list-item";

interface BannerPropertyProps {
    autoplay?: boolean,
    autoplaySpeed?: number,
    centerMode?: boolean,
    speed?: number,
    variableWidth?: boolean,
    vertical?: boolean,
    infinite?: boolean,
    slidesToShow?: number,
    slidesToScroll?: number,
    pauseOnHover?: boolean,
    dots?: boolean,
    pauseOnDotsHover?: boolean,
    cssEase?: string,
    fade?: boolean,
    draggable?: boolean,
    arrows?: boolean,
    zIndex?: number,
    asNavFor?: string
}

interface BannerPropertyThumbsProps {
    slidesToShow?: number,
    slidesToScroll?: number,
    asNavFor?: string,
    dots?: boolean,
    centerMode?: boolean,
    swipeToSlide?: boolean,
    focusOnSelect?: boolean,
    centerPadding?: string
}

interface BannerPropertyPropsFull {
    settings: BannerPropertyProps;
    settingsThumbs: BannerPropertyThumbsProps;
    mediaGallery: string[];
}

const BannerProperty = ({settings, settingsThumbs, mediaGallery}: BannerPropertyPropsFull) => {
    let [nav1, setNav1] = useState<any>();
    let [nav2, setNav2] = useState<any>();
    const { t } = useTranslation();

    return (
      <div>
          <div className="topBanner__container">
              <div className="carousel__container">
                  <Slider {...settings}
                          ref={setNav1}
                          asNavFor={nav2}>
                      {mediaGallery.map(img =>
                          <div key={img}>
                              <div className="">
                                  <div className="background">
                                      <div className="background__inner topBanner__background-inner-position" style={{ backgroundImage: `url(${img})` }}>

                                      </div>
                                  </div>
                              </div>
                          </div>
                      )}
                  </Slider>
              </div>
          </div>
          <div className="mt-4 banner-secondary-property">
              {mediaGallery.length >= 3 &&
                  <Slider {...settingsThumbs}
                          ref={setNav2}
                          asNavFor={nav1}
                          arrows={true}
                          slidesToShow={3}
                          swipeToSlide={true}
                          focusOnSelect={true}>
                      {mediaGallery.map(img =>
                          <div className="cursor-pointer" key={img}>
                              <img src={`${img}`} />
                          </div>
                      )}
                      <div>
                          <img src={`${process.env.PUBLIC_URL}/QR1.png`} />
                      </div>
                  </Slider>
              }
          </div>
      </div>
    );
}

export default BannerProperty;
